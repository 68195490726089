require('team-admin')
angular.module('team-admin')
  .directive('teamCalendar', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./team-calendar.html'),
      controller: function($scope, $rootScope, $routeParams, $location, $log,
        $timeout, _, Calendar, Event, Game, Alerts, dialog, preloadScript,
        moment, i18ng, snGlobals, featureToggles, debounceCallback, pageViewHandler, ENV, launchDarklyFlags, Organization, Subvenue) {

        // cut off events list at this number with e.g. "+2 more" link
        $scope.MAX_SHOWN = 3
        var POPOVER_KEY = $scope.POPOVER_KEY = 'selectedEvent'

        var loading = $scope.setAs('loading')

        preloadScript(`${ ENV.urls.sportAdminElements }/event-add-edit-modal-element/element.js`)
        preloadScript(`${ ENV.urls.sportAdminElements }/game-add-edit-modal-element/element.js`)

        $scope.$watchGroup(['team.id', 'weeks'], function(group) {
          if (_.all(group)) loadEvents()
        })

        var findAll = debounceCallback(Calendar.findAll)
        function loadEvents() {
          loading(true)
          var query = {
            // per_page: 100, // this is not currently paginated, but it can't hurt
            start_date: _.first(_.first($scope.weeks)).startOf('day').format(),
            end_date: _.last(_.last($scope.weeks)).endOf('day').format(),
            show_event_attendees: 1,
          }
          findAll(query, { load: 'all', endpoint: `/v3/calendar/team/${ $scope.team.id }` })
            .then($scope.setAs('events'))
            .catch($log.error)
            .finally(loading.bind(null, false))
        }


        function ymd(date) { return { y: date.year(), m: date.month(), d: date.date(), wd: date.day(), absDay: +date.format('YYYYMMDD') } }
        $scope.$watch('nowDate', function(now) { if (now) $scope.nowYMD = ymd(now) })

        // Index events into a year+month+day-keyed hash
        function splitEventsIntoDays() {
          var byDay = $scope.eventsByDay = {}
          var sortedEvents = _.sortBy($scope.events, Calendar.orderByTime)
          _.each(sortedEvents, function(event) {
            var date = moment.tz(event.start_date_time, (event.local_timezone || $scope.team.timezone))
            var d = ymd(date)
            event.absDay = d.absDay

            byDay[d.y] ||= {}
            byDay[d.y][d.m] ||= {}
            byDay[d.y][d.m][d.d] ||= []
            byDay[d.y][d.m][d.d].push(event)
          })
        }

        async function getOrgSubvenues() {
          const subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' }).filter((i) => 'venue_name' in i)
          return subvenues
        }

        $scope.$watchCollection('events', splitEventsIntoDays)
        $scope.listenToRoot('event:save', splitEventsIntoDays)
        $scope.listenToRoot('game:save', splitEventsIntoDays)
        $scope.listenToRoot('event:save_recurring', loadEvents)

        moment.locale('en', { calendar: i18ng.t('SCHEDULE.CALENDAR.MOMENT_CALENDAR', { returnObjectTrees: true }) })

        // Clear and repopulate arrays for calendar view when viewDate changes
        $scope.$watch('viewDate', function(viewDate) {
          if (!viewDate) return

          $scope.viewYMD = ymd(viewDate)

          var date = viewDate.clone().startOf('month').startOf('week')
          var month = viewDate.month()
          var weeks = $scope.weeks = []
          var currentWeek, currentDate

          while (weeks.length < 2 || date.month() === month || date.day() > 0) {
            if (date.day() === 0) weeks.push(currentWeek = [])
            currentWeek.push(currentDate = date.clone())
            _.extend(currentDate, ymd(currentDate)) // add convenience properties
            date.add(1, 'days')
          }
        })

        $scope.showPopover = function(event) {
          $scope.snPopover.hide(POPOVER_KEY)
            .finally(function() {
              $scope.snPopover.show(POPOVER_KEY, 300, true)
                .then(function() { $scope.selectedEvent = event })
            })
        }

        /** @deprecated */
        // TODO: Remove when Game modal is done and deployed to production
        const addGameUsingOldModal = (type = 'game') => {
          pageViewHandler.fireEvent(`Add ${type}`, 8)
          dialog.confirm({
            directive: `add-${type}`,
            scope: $scope,
            attrs: { team: $scope.team }
          })
            .then($scope.addEvent)
        }

        // TODO: Add new Game modal
        $scope.addGameModal = async function(type = 'game') {
          if (!launchDarklyFlags.gameAddEditModal) {
            return addGameUsingOldModal(type)
          }

          const modal = document.createElement('se-game-add-edit-modal')
          modal.seDataTriggerId = 'se-add-game-tc'
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i))

          modal.seOpen = true
        }

        $scope.addEventModal = async function() {

          pageViewHandler.fireEvent('Add event', 8)
          const modal = document.createElement('se-event-add-edit-modal')
          document.body.append(modal)
          modal.seDataTriggerId = 'se-add-event-tc'
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i) || [])

          modal.seOpen = true
        }

        $scope.addEvent = function(event) {
          $scope.events.push(event)
        }

        const deleteEventModal = (event, prev) => {
          const type = event.event_type

          if (type === 'game' && !launchDarklyFlags.gameAddEditModal) {
            return prev()
          }

          const modal = document.createElement('se-event-add-edit-modal')
          modal.seDataTriggerId = `se-delete-${ type }-tc`
          modal.seEventId = event.id
          modal.toDelete = type
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => loadEvents())

          modal.seTeam = JSON.stringify($scope.team)

          if (event.is_affiliated) {
            modal.seIsEventAffiliated = true
          }

          modal.seOpen = true
        }

        $scope.removeEventModal = function(event) {
          $scope.snPopover.hide(POPOVER_KEY)
          const prevMethod = () => dialog.confirm({
            directive: 'remove-events',
            attrs: {
              team: $scope.team,
              events: [event]
            }
          }).then($scope.removeEvents)

          deleteEventModal(event, prevMethod)
        }

        $scope.removeEvents = function(deletedEvents) {
          _.each(deletedEvents, function(event) {
            var eventInList = _.findWhere($scope.events, { id: event.id })
            _.pull($scope.events, eventInList)
          })
          var opts = {
            count: deletedEvents.length,
            event: deletedEvents[0]
          }
          Alerts.success('SCHEDULE.REMOVE.success', opts)
        }

      }
    }
  })
